<div *ngIf="job$ | async as job">
  <ng-container *ngIf="!mobileVersion">
    <app-icon icon="/assets/img/close.svg" class="close" (click)="closeModal()"></app-icon>

    <div class="text-white p-3 p-md-5 mw-100">
      <div class="row mb-5">
        <div *ngIf="!job.isDirectOffer" class="col-md-6">
          <h2 class="pb-2">{{ job.title }}</h2>
          <p class="mb-4 newlines">{{ job.shortDescription }}</p>
          <div class="row my-4">
            <div class="col-12 col-sm-4 mb-2 mb-sm-0 text-center">
              <div
                class="h-100 align-items-center border border-white rounded"
                [ngClass]="{ 'd-flex': !job.negotiable, 'p-2': !job.negotiable, 'p-3': job.negotiable }">
                <span *ngIf="job.feeType.slug !== 'fee'" class="h3 flex-grow-1 text-break" translate>
                  {{ job.feeType.translateKey }}
                </span>
                <ng-container *ngIf="job.feeType.slug === 'fee'">
                  <span
                    *ngIf="job.hideRate && (userAlreadyApplied$ | async) === false"
                    class="h3 flex-grow-1 text-break"
                    translate>
                    {{ job.hideRateFee }}
                  </span>
                  <app-money
                    *ngIf="!job.hideRate || (userAlreadyApplied$ | async) === true"
                    [amount]="job.totalRate"
                    class="h3 flex-grow-1 text-break"></app-money>
                  <span *ngIf="job.negotiable" class="p text-center">{{ 'Negotiable' | translate }}</span>
                </ng-container>
              </div>
            </div>
            <div class="col-12 col-sm-8">
              <div class="border p-2 rounded text-center h-100">
                <app-icon class="mx-auto my-2" fill="white" icon="/assets/img/location.svg"></app-icon>
                <div class="m-0 font-weight-light">
                  <div *ngIf="job.workFromHome">
                    <span translate>Online (work from home)</span>
                  </div>
                  <div *ngFor="let jobCountry of job?.countries">
                    <span class="job-city" *ngIf="jobCountry.city">{{ jobCountry.city }}</span>
                    <span translate>{{ jobCountry.country.translateKey }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-12 col-sm-4 mb-2 mb-sm-0">
              <div class="border p-2 rounded text-center h-100">
                <app-icon class="mx-auto my-2" fill="white" icon="/assets/img/briefcase.svg"></app-icon>
                <span class="d-block nowrap" translate>{{ job.category.translateKey }}</span>
              </div>
            </div>
            <div class="col-12 col-sm-8 mb-2 mb-sm-0">
              <div class="border p-2 rounded text-center h-100">
                <app-icon class="mx-auto my-2" fill="white" icon="/assets/img/clock.svg"></app-icon>
                <ng-container *ngIf="job.dates.length > 0">
                  <span class="d-block">
                    {{ job.firstDate | dateRange: { exact: job.exactDate, withTime: false } }}
                    <ng-container *ngIf="job.dates.length > 1">
                      - {{ job.lastDate | dateRange: { exact: job.exactDate, withTime: false } }}
                    </ng-container>
                  </span>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-12">
              <div class="p-2 border rounded">
                <div class="row mb-3">
                  <div class="col-12">
                    <h3 translate>
                      Fee
                      <span class="p">{{ job.negotiable ? 'Negotiable' : 'details' | translate }}</span>
                    </h3>
                  </div>
                </div>
                <div class="row my-3" *ngIf="job.feeType.slug != 'fee'">
                  <div class="col-6"><strong translate>Payment</strong></div>
                  <div class="col-6 text-right" translate>
                    <span class="h3 flex-grow-1 text-break" translate>{{ job.feeType.translateKey }}</span>
                  </div>
                </div>
                <div class="row my-3" *ngIf="job.rate">
                  <div class="col-6"><strong translate>Fee</strong></div>
                  <div class="col-6 text-right" translate>
                    <span
                      *ngIf="job.hideRate && (userAlreadyApplied$ | async) === false"
                      class="h3 flex-grow-1 text-break"
                      translate>
                      {{ job.hideRateFee }}
                    </span>
                    <app-money
                      *ngIf="!job.hideRate || (userAlreadyApplied$ | async) === true"
                      [amount]="job.rate"
                      class="h3 flex-grow-1 text-break"></app-money>
                  </div>
                </div>
                <div
                  class="row my-3"
                  *ngIf="job.extraFees && (!job.hideRate || (userAlreadyApplied$ | async) === true)">
                  <div class="col-6"><strong translate>Buyout</strong></div>
                  <div class="col-6 text-right">
                    <app-money [amount]="job.extraFees" class="h3 flex-grow-1 text-break"></app-money>
                  </div>
                </div>
                <div
                  class="row my-3"
                  *ngIf="!job.extraFees && (!job.hideRate || (userAlreadyApplied$ | async) === true)">
                  <div class="col-6"><strong translate>Buyout</strong></div>
                  <div class="col-6 text-right">
                    <span class="h3 flex-grow-1 text-break" translate>Included</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-12">
              <div class="p-2 border rounded">
                <div class="row my-3">
                  <div class="col-4"><strong translate>Traveling cost:</strong></div>
                  <div class="col-8 text-right" *ngIf="job.travelingCost == null" translate>No</div>
                  <div class="col-8 text-right" *ngIf="job.travelingCost != null" translate>
                    Yes, {{ job.travelingCost }} euros per KM.
                    <span *ngIf="job.maxTravelingCost">Max. {{ job.maxTravelingCost }} euros.</span>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-4"><strong translate>Parking cost:</strong></div>
                  <div class="col-8 text-right" *ngIf="!job.parkingCost" translate>No</div>
                  <div class="col-8 text-right" *ngIf="job.parkingCost" translate>
                    Yes
                    <span *ngIf="job.maxParkingCost">, Max. {{ job.maxParkingCost }} euros.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-3" *ngIf="job.exactDate">
            <div class="col-12">
              <div class="p-2 border rounded">
                <div class="row mb-3">
                  <div class="col-12">
                    <h3 translate>Date(s)</h3>
                  </div>
                </div>
                <div class="row my-3" *ngFor="let jobDate of job.getSortedDates; let i = index">
                  <div class="col-4">
                    <strong translate>{{ 'dayX' | translate: { day: i + 1 } }}</strong>
                  </div>
                  <div class="col-8 text-right" translate>{{ jobDate | dateRange: { exact: job.exactDate } }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="row my-3" *ngIf="!job.exactDate">
            <div class="col-12">
              <div class="p-2 border rounded">
                <div class="row mb-3">
                  <div class="col-12">
                    <h3 translate>Date(s)</h3>
                  </div>
                </div>
                <div class="row my-3" *ngFor="let jobDate of job.getSortedDates; let i = index">
                  <div class="col-6">
                    <strong translate>Period</strong>
                  </div>
                  <div class="col-6 text-right">
                    <div translate class="mr-1">{{ jobDate | dateRange: { exact: job.exactDate } }}</div>
                  </div>
                  <div class="col-6">
                    <strong translate>Total days</strong>
                  </div>
                  <div class="col-6 text-right">
                    <div translate class="mr-1">{{ jobDate.days }}</div>
                  </div>
                  <div class="col-6">
                    <strong translate>Avg. hours per day</strong>
                  </div>
                  <div class="col-6 text-right">
                    <div translate class="mr-1">{{ jobDate.hours_per_day }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="p-2 border rounded">
                <div class="row mb-3">
                  <div class="col-12">
                    <h2 translate>Publication details</h2>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-6">
                    <strong translate>Publishing channels</strong>
                  </div>
                  <div class="col-6 text-right">
                    <div *ngFor="let channel of job?.formattedPublishingChannels" translate class="mr-1">
                      {{ channel }}
                    </div>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-6">
                    <strong translate>Broadcast countries</strong>
                  </div>
                  <div class="col-6 text-right">
                    <ng-container *ngIf="job.broadcastCountries == 'worldwide'">
                      {{ job.broadcastCountries | translate }}
                    </ng-container>
                    <ng-container *ngIf="job.broadcastCountries == 'other'">
                      <div *ngFor="let country of job?.licenceCountries">{{ country.translateKey | translate }}</div>
                    </ng-container>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-6">
                    <strong translate>Licence duration</strong>
                  </div>
                  <div class="col-6 text-right">
                    <span translate>{{ job.licenceDuration }}</span>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-6">
                    <strong translate>Commercial use</strong>
                  </div>
                  <div class="col-6 text-right">
                    <span translate>{{ job.commercialUse ? 'Yes' : 'No' }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row my-3">
            <div class="col-12">
              <div class="p-2 border rounded">
                <div class="row mb-3">
                  <div class="col-12">
                    <h3 translate>Talent description</h3>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-6" translate><strong>Gender</strong></div>
                  <div class="col-6 text-right">
                    <div *ngFor="let gender of job?.formattedGenders" translate class="mr-1">{{ gender }}</div>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-6">
                    <strong translate>Age range</strong>
                  </div>
                  <div class="col-6 text-right">{{ job.startAge }} - {{ job.endAge }}</div>
                </div>
                <div class="row my-3">
                  <div class="col-6">
                    <strong translate>Talents requested from</strong>
                  </div>
                  <div class="col-6 text-right">
                    <ng-container *ngIf="job?.talentCountries.length === 0" translate>No</ng-container>
                    <div *ngFor="let jobTalentCountry of job?.talentCountries">
                      <ng-container *ngIf="jobTalentCountry.city">{{ jobTalentCountry.city }},&nbsp;</ng-container>
                      <span>{{ jobTalentCountry.country.translateKey | translate }}</span>
                      <span *ngIf="jobTalentCountry.distance">&nbsp;- +{{ jobTalentCountry.distance }} km</span>
                    </div>
                  </div>
                </div>
                <div *ngFor="let detail of jobFilters" class="row my-3">
                  <div class="col-6">
                    <strong translate>{{ detail.translateKey }}</strong>
                  </div>
                  <div class="col-6 text-right">
                    <ng-container *ngIf="detail.type !== 'color'">{{ detail.value }}</ng-container>
                    <ng-container *ngIf="detail.type === 'color'">
                      <div class="d-flex float-right">
                        <app-color-indicator [color]="detail.value[0]"></app-color-indicator>
                        <span>-</span>
                        <app-color-indicator [color]="detail.value[1]"></app-color-indicator>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-12">
              <div class="p-2 border rounded">
                <div class="row my-3">
                  <div class="col-6"><strong translate>Food included</strong></div>
                  <div class="col-6 text-right" translate>{{ job.foodIncluded ? 'Yes' : 'No' }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="job.isDirectOffer" class="col-md-6">
          <h2 class="pb-2">{{ job.title }}</h2>
          <p class="mb-4 newlines">{{ job.shortDescription }}</p>
          <div class="row my-4">
            <div class="col-12 col-sm-4 mb-2 mb-sm-0 text-center">
              <div
                class="h-100 align-items-center border border-white rounded"
                [ngClass]="{ 'd-flex': !job.negotiable, 'p-2': !job.negotiable, 'p-3': job.negotiable }">
                <span *ngIf="job.feeType.slug !== 'fee'" class="h3 flex-grow-1 text-break" translate>
                  {{ job.feeType.translateKey }}
                </span>
                <ng-container *ngIf="job.feeType.slug === 'fee'">
                  <span
                    *ngIf="job.hideRate && (userAlreadyApplied$ | async) === false"
                    class="h3 flex-grow-1 text-break"
                    translate>
                    {{ job.hideRateFee }}
                  </span>
                  <app-money
                    *ngIf="!job.hideRate || (userAlreadyApplied$ | async) === true"
                    [amount]="job.totalRate"
                    class="h3 flex-grow-1 text-break"></app-money>
                  <span *ngIf="job.negotiable" class="p text-center">{{ 'Negotiable' | translate }}</span>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="row my-3">
            <div class="col-12">
              <div class="p-2 border rounded">
                <div class="row my-3">
                  <div class="col-4"><strong translate>Traveling cost:</strong></div>
                  <div class="col-8 text-right" *ngIf="job.travelingCost == null" translate>No</div>
                  <div class="col-8 text-right" *ngIf="job.travelingCost != null" translate>
                    Yes, {{ job.travelingCost }} euros per KM.
                    <span *ngIf="job.maxTravelingCost">Max. {{ job.maxTravelingCost }} euros.</span>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-4"><strong translate>Parking cost:</strong></div>
                  <div class="col-8 text-right" *ngIf="!job.parkingCost" translate>No</div>
                  <div class="col-8 text-right" *ngIf="job.parkingCost" translate>
                    Yes
                    <span *ngIf="job.maxParkingCost">, Max. {{ job.maxParkingCost }} euros.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <app-button
            *ngIf="!authenticated"
            (click)="openLoginModal()"
            buttonStyle="purple"
            class="mb-3 rounded d-none d-md-block">
            <div class="px-2" translate>LOGIN TO APPLY</div>
          </app-button>
          <ng-container *ngIf="authenticated && user">
            <app-button
              *ngIf="!userIsTalent"
              [isDisabled]="true"
              buttonStyle="purple"
              class="mb-3 rounded d-none d-md-block">
              <div class="px-2" translate>ONLY TALENTS CAN APPLY</div>
            </app-button>
            <ng-template #showLoader>
              <app-button [isBusy]="true" [isDisabled]="true"></app-button>
            </ng-template>
            <ng-container *ngIf="!isBusy; else showLoader">
              <app-button
                *ngIf="!user.idVerification"
                [isDisabled]="true"
                buttonStyle="purple"
                class="mb-3 rounded d-none d-md-block">
                <div class="px-2" translate>ID VERIFICATION REQUIRED TO APPLY</div>
              </app-button>
              <ng-container *ngIf="userIsTalent && user.id != job.user.id && user.idVerification">
                <app-button
                  *ngIf="(checkProfilesCompletion() | async) === false"
                  [isDisabled]="true"
                  buttonStyle="purple"
                  class="mb-3 rounded d-none d-md-block">
                  <div class="px-2" translate>COMPLETE YOUR PROFILE</div>
                </app-button>
                <ng-container *ngIf="(checkProfilesCompletion() | async) === true">
                  <ng-container *ngIf="(userAlreadyApplied$ | async) === false">
                    <app-button (click)="applyToJob()" buttonStyle="purple" class="mb-3 rounded d-none d-md-block">
                      <div class="px-2" translate>APPLY</div>
                    </app-button>
                  </ng-container>

                  <ng-container *ngIf="(userAlreadyApplied$ | async) === true">
                    <ng-container *ngIf="userJobs$ | async as jobApplication">
                      <app-button
                        *ngIf="jobApplication !== null"
                        (click)="cancelApplication(jobApplication)"
                        buttonStyle="purple"
                        class="mb-3 rounded d-none d-md-block">
                        <div class="px-2" translate>Click to cancel application</div>
                      </app-button>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
          <div class="small mb-3 d-none d-md-block" translate>
            Price is excl. 15% commission. Commission height may be lower, depending on your membership.
          </div>
          <div class="d-flex">
            <!-- <app-button
              buttonStyle="white-outline"
              class="mb-3 mr-2 rounded flex-grow-1"
              (click)="openReportModal()"
              translate>
              Report
            </app-button> -->
            <app-button
              #sharebutton
              buttonStyle="white-outline"
              class="mb-3 mr-2 rounded flex-grow-1"
              (click)="shareUrl()">
              <ng-container *ngIf="!copied" translate>Share</ng-container>
              <ng-container *ngIf="copied" translate>Copied url</ng-container>
            </app-button>
          </div>

          <app-carousel [items]="job.media" [options]="slideOption" class="d-block w-100" *ngIf="job.media.length > 0">
            <ng-template #carouselSlideTpl let-item="item">
              <app-image [image]="item.url" class="cover rounded-sm"></app-image>
            </ng-template>
          </app-carousel>

          <div class="p-5 mt-4 custom-border rounded">
            <div class="text-center">
              <div class="pb-3">
                <app-image image="/assets/img/caster-default.png" class="profile-pic d-inline-block"></app-image>
              </div>
              <h2 class="w-100 pb-2">Caster</h2>
              <app-rating
                [average]="job.user.averageReview"
                [count]="job.user.totalReviews"
                class="rating mb-3"
                *ngIf="job.user.totalReviews"></app-rating>
              <div class="d-flex align-items-center justify-content-center">
                <app-icon class="mr-1 d-inline-block" fill="white" icon="/assets/img/location.svg"></app-icon>
                <h5 class="d-inline-block font-weight-light">{{ job.user.country?.name }}</h5>
              </div>
            </div>
            <hr />

            <div class="row mb-3 text-center">
              <div class="col-12 col-sm-6 d-flex flex-column justify-content-center">
                <div class="d-flex justify-content-center align-items-center">
                  <app-icon class="d-inline-block size-4 mr-2" fill="white" icon="/assets/img/briefcase.svg"></app-icon>
                  <span class="h4 px-1">{{ job.user.totalJobs }}</span>
                </div>
                <div class="d-block" translate>Casterbee Jobs</div>
              </div>
              <div class="col-12 col-sm-6 d-flex flex-column justify-content-center">
                <div class="d-flex justify-content-center align-items-center" translate>
                  <app-icon
                    class="d-inline-block size-4 mr-2"
                    icon="/assets/img/language.svg"
                    stroke="white"
                    strokeWidth="2"></app-icon>
                  Languages
                </div>
                <div class="d-block">
                  <div *ngFor="let language of job?.user.languages">{{ language.translateKey | translate }}</div>
                </div>
              </div>
            </div>
            <hr />
            <div class="row mb-4">
              <div class="col-12 col-sm-4 mb-2 mb-sm-0 d-flex flex-column text-center">
                <app-icon
                  class="mx-auto mb-2 size-5"
                  icon="/assets/img/mobile.svg"
                  stroke="white"
                  strokeWidth="2"></app-icon>
                <span class="d-block flex-grow-1 my-auto" translate>Mobile Verification</span>
                <app-icon
                  [stroke]="job.user?.mobileVerification ? '#00cb56' : '#FFFFFF44'"
                  class="size-5 mx-auto mt-2"
                  icon="/assets/img/checkmark.svg"></app-icon>
              </div>
              <div class="col-12 col-sm-4 mb-2 mb-sm-0 d-flex flex-column text-center">
                <app-icon class="mx-auto mb-2 size-5" icon="/assets/img/email.svg" stroke="white"></app-icon>
                <span class="d-block flex-grow-1 my-auto" translate>E-mail Verification</span>
                <app-icon
                  [stroke]="job.user.emailVerification ? '#00cb56' : '#FFFFFF44'"
                  class="size-5 mx-auto mt-2"
                  icon="/assets/img/checkmark.svg"></app-icon>
              </div>
              <div class="col-12 col-sm-4 mb-2 mb-sm-0 d-flex flex-column text-center">
                <app-icon class="mx-auto mb-2 size-5" fill="#fff" icon="/assets/img/profile.svg"></app-icon>
                <span class="d-block flex-grow-1 my-auto" translate>ID Verification</span>
                <app-icon
                  [stroke]="job.user.idVerification ? '#00cb56' : '#FFFFFF44'"
                  class="size-5 mx-auto mt-2"
                  icon="/assets/img/checkmark.svg"></app-icon>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr class="my-4" />

      <div class="row" *ngIf="recentlyViewed$ | async as jobs">
        <ng-container *ngIf="jobs.length > 0">
          <div class="col-12 mb-3">
            <h2 translate>Recently Viewed</h2>
          </div>
          <app-small-job-card
            *ngFor="let job of jobs; let i = index"
            [themeIndex]="i % 3"
            [job]="job"
            (jobEvent)="openJobModal($event)"
            class="col-12 col-sm-6 col-md-4 d-flex"></app-small-job-card>
        </ng-container>
      </div>

      <div
        class="apply-mobile d-flex d-lg-none d-md-none d-xl-none position-fixed align-items-center w-100 flex-column">
        <div class="apply-mobile-container d-flex flex-column">
          <app-button *ngIf="!authenticated" (click)="openLoginModal()" buttonStyle="purple" class="mb-3 rounded">
            <div class="px-2" translate>LOGIN TO APPLY</div>
          </app-button>
          <ng-container *ngIf="authenticated && user">
            <app-button *ngIf="!userIsTalent" [isDisabled]="true" buttonStyle="purple" class="mb-3 rounded">
              <div class="px-2" translate>ONLY TALENTS CAN APPLY</div>
            </app-button>
            <app-button *ngIf="!user.idVerification" [isDisabled]="true" buttonStyle="purple" class="mb-3 rounded">
              <div class="px-2" translate>ID VERIFICATION REQUIRED TO APPLY</div>
            </app-button>

            <ng-template #showLoader>
              <app-button [isBusy]="true" [isDisabled]="true"></app-button>
            </ng-template>
            <ng-container *ngIf="!isBusy; else showLoader">
              <ng-container *ngIf="userIsTalent && user.id != job.user.id && user.idVerification">
                <app-button
                  *ngIf="(checkProfilesCompletion() | async) === false"
                  [isDisabled]="true"
                  buttonStyle="purple"
                  class="mb-3 rounded">
                  <div class="px-2" translate>COMPLETE YOUR PROFILE</div>
                </app-button>
                <ng-container *ngIf="(checkProfilesCompletion() | async) === true">
                  <ng-container *ngIf="(userAlreadyApplied$ | async) === false">
                    <app-button (click)="applyToJob()" buttonStyle="purple" class="mb-3 rounded">
                      <div class="px-2" translate>APPLY</div>
                    </app-button>
                  </ng-container>
                  <app-button
                    *ngIf="(userAlreadyApplied$ | async) === true"
                    [isDisabled]="true"
                    buttonStyle="purple"
                    class="mb-3 rounded">
                    <div class="px-2" translate>ALREADY APPLIED</div>
                  </app-button>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>
