import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { Chat } from '@core/models/chat.model';
import { Message } from '@core/models/message.model';
import { PaginatedResults } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  public constructor(private http: HttpClient) {}

  public loadChats(page: number = 0, limit: number = 10): Observable<PaginatedResults<Chat>> {
    const searchParams = {
      page: `${page}`,
      limit: `${limit}`,
    };

    return this.http.get(`${environment.apiBaseUrl}/messaging/chats`, { params: searchParams }).pipe(
      map((data: PaginatedResults<Chat>) => {
        data.results = data.results?.map((item: Chat) => plainToClass(Chat, item));
        return data;
      }),
    );
  }

  public sendMessage(message: any): Observable<Message> {
    return this.http
      .post(`${environment.apiBaseUrl}/messaging/chat/${message.chatId}/message`, message)
      .pipe(map((data: Message) => plainToClass(Message, data)));
  }

  public initiateChat(senderId: string, recipientId: string): Observable<Chat> {
    return this.http
      .post(`${environment.apiBaseUrl}/messaging/chat`, { senderId, recipientId })
      .pipe(map((data: Chat) => plainToClass(Chat, data)));
  }

  public loadChat(chatId: string, messageLimit?: number | null): Observable<Chat> {
    const params: { limit?: string } = {};

    if (messageLimit) {
      params.limit = `${messageLimit}`;
    }

    return this.http
      .get(`${environment.apiBaseUrl}/messaging/chat/${chatId}`, { params })
      .pipe(map((data: Chat) => plainToClass(Chat, data)));
  }

  public loadMoreMessages(chatId: string, limit: number = 20, lastId?: string | null): Observable<Message[]> {
    const params: { limit: string; lastId?: string } = {
      limit: `${limit}`,
    };

    if (lastId) {
      params.lastId = `${lastId}`;
    }

    return this.http.get(`${environment.apiBaseUrl}/messaging/chat/${chatId}/messages`, { params }).pipe(
      map((messages: Message[]) => {
        return messages.map((message) => plainToClass(Message, message));
      }),
    );
  }

  public markMessagesAsRead(chatId: string): Observable<any> {
    return this.http.post(`${environment.apiBaseUrl}/messaging/chat/${chatId}/mark-read`, {});
  }

  public hideChat(chatId: string): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/messaging/chat/${chatId}/hide`);
  }
}
