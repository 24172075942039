<app-mobile-modal-dialog #formPostJob [backgroundColor]="'rgb(123 123 123 / 10%)'">
  <ng-container *ngIf="jobInfo; else success">
    <div class="modal-body">
      <div class="job-info">
        <h6 translate>
          {{isDirect && existJob === undefined ? 'Direct offer is 100% Free' : 'Post a job is 100% Free'}}
        </h6>
        <p>
          {{'Only the fee excl. VAT will be invoiced' | translate}}
          <br />
          <br />
          {{'It is not allowed to book outside of Casterbee' | translate}}
          <br />
          <br />
          {{'Keep it safe, keep us informed' | translate}}
        </p>
        <p class="text-bold" translate>Ask for help</p>
        <div class="contact">
          <div class="wrap">
            <img src="/assets/img/mobile/contact.png" alt="" />
            <ol>
              <li>Jorge Bijleveld</li>
              <li>06 4216 6461</li>
              <li>caster@casterbee.nl</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="infoJob()" class="btn-submit" translate>Understood</button>
    </div>
  </ng-container>
  <ng-template #success>
    <ng-container *ngIf="submitSuccess && message?.type && message?.type == 'success' else showForm">
      <div class="modal-body">
        <app-mobile-success [message]="message?.message"></app-mobile-success>
      </div>
      <div class="modal-footer">
        <a [href]="message.returnUrl" class="btn-view" translate>View job</a>
      </div>
    </ng-container>
  </ng-template>
  <ng-template #showForm>
    <div class="text-center" *ngIf="isLoading">
      <span class="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
      <p class="text-center mt-3">Loading...</p>
    </div>
    <div *ngIf="!isLoading">
      <form
        class="form-job post-job-modal-container"
        *ngIf="formBuilder"
        [formGroup]="formBuilder"
        (ngSubmit)="onSubmit()">
        <div class="modal-body" style="max-height: unset !important; height: calc(100vh - 229px) !important">
          <h4 *ngIf="isDirect && existJob === undefined">{{'Offer Job' | translate}}</h4>
          <h4 *ngIf="!isDirect && existJob === undefined">{{'Create Job' | translate}}</h4>
          <h4 *ngIf="isDirect && existJob !== undefined">{{'Edit Offered Job' | translate}}</h4>
          <h4 *ngIf="!isDirect && existJob !== undefined">{{'Edit Job' | translate}}</h4>

          <div class="guideline" *ngIf="!isDirect && !(isEdited || isExistingJob)">
            <div class="floating-text-guideline">
              <img src="/assets/img/light-icon.svg" />
              <h3 translate>Tips & directions</h3>
              <p translate class="guideline-subtext">
                Improve the quality of the Talents by following these guidelines
              </p>
              <p class="row-list">
                <strong>€1.500 - €4.000</strong>
                <span translate>Pro Talents (o.a. remembering texts, acting emotions)</span>
              </p>
              <p class="row-list">
                <strong>€500 - €800</strong>
                <span translate>Actors/Models with camera- and text experience</span>
              </p>
              <p class="row-list">
                <strong>€350+</strong>
                <span translate>Experienced Talents</span>
              </p>
              <p class="row-list">
                <strong><€200</strong>
                <span translate>Beginners / (edel-) extras</span>
              </p>
              <p class="row-list">
                <strong>€25 - €199</strong>
                <span translate>Hobbyists / extras</span>
              </p>
            </div>
          </div>

          <!-- Title -->
          <div class="form-group">
            <label translate>Title</label>
            <input type="text" class="form-control" formControlName="title" />
          </div>
          <div class="separator"></div>
          <!-- Category -->
          <div class="category wrap">
            <h6 class="title" translate>Category</h6>
            <ng-container *ngFor="let category of categories; let i = index;">
              <div class="custom-control custom-checkbox" *ngIf="i < 4;">
                <input
                  type="checkbox"
                  class="custom-control-input custom-control"
                  id="{{category.id}}"
                  [value]="category.id"
                  [checked]="formBuilder.get('categoryId')?.value == category.id"
                  (click)="categoryIsChecked(category)" />
                <label
                  class="custom-control-label"
                  [ngClass]="formBuilder.get('categoryId')?.value === null ? 'ng-invalid' : ''"
                  for="{{category.id}}">
                  {{ category.translateKey | translate }}
                </label>
              </div>
            </ng-container>
            <ng-container *ngIf="categories.length > 4">
              <div class="collapse" id="moreCategory">
                <ng-container *ngFor="let category of categories; let i = index;">
                  <div class="custom-control custom-checkbox" *ngIf="i > 4;">
                    <input
                      type="checkbox"
                      class="custom-control-input custom-control"
                      id="{{category.id}}"
                      [value]="category.id"
                      [checked]="formBuilder.get('categoryId')?.value == category.id"
                      (click)="categoryIsChecked(category)" />
                    <label class="custom-control-label" for="{{category.id}}">
                      {{ category.translateKey | translate }}
                    </label>
                  </div>
                </ng-container>
              </div>
              <button
                type="button"
                data-id="#moreCategory"
                class="button-readmore"
                data-open="View more"
                data-close="Show less"
                data-toggle="collapse"
                role="button"
                aria-expanded="false"
                (click)="viewMore($event)">
                View more
              </button>
            </ng-container>
          </div>
          <!-- Country -->
          <div class="countries">
            <div class="form-group" formArrayName="countries" *ngIf="dataCountries">
              <div
                *ngFor="let countryControl of countries.controls; let i = index"
                [formGroupName]="i"
                class="location-{{ i }}">
                <label>{{i === 0 ? 'Location' : 'Location '+i}}</label>
                <app-select class="w-100 rounded-sm" formControlName="countryId" iconColor="white" [searchable]="true">
                  <app-option
                    *ngFor="let country of dataCountries"
                    [value]="country.id"
                    [searchValue]="country.translateKey | translate"
                    class="d-flex w-100 py-1 align-items-center">
                    <img
                      class="flag mr-2"
                      src="https://storage.googleapis.com/casterbee-public/img/flags/{{
                                country.isoCode.toLowerCase()
                              }}.svg"
                      alt="{{country.id}}" />
                    <span class="text-white" translate>{{ country.translateKey }}</span>
                  </app-option>
                </app-select>
                <app-geolocate-field
                  class="d-flex rounded-sm w-100"
                  formControlName="city"
                  [countryCode]="getCountryCode(countryControl)"
                  [placeholder]="'City' | translate"></app-geolocate-field>
              </div>
              <div class="text-center" *ngIf="countries.controls.length > 1">
                <button
                  type="button"
                  (click)="removeCountry(countries.controls.length -1)"
                  class="btn btn-remove"
                  translate>
                  Remove location
                </button>
              </div>
              <div class="text-center">
                <button type="button" (click)="addCountry()" class="btn btn-add" translate>+ Add location</button>
              </div>
            </div>
          </div>
          <!-- Work from option -->
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input custom-control"
              id="workFromOption"
              (click)="setWorkFromHome()"
              formControlName="workFromHome" />
            <label class="custom-control-label" for="workFromOption">{{ workFromHomeText | translate }}</label>
          </div>
          <!-- Dates -->
          <div class="form-date">
            <div class="separator"></div>
            <p translate>Dates</p>
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input custom-control"
                id="exactDateTrue"
                [checked]="formBuilder.get('exactDate')?.value === true"
                (click)="exactDayIsChecked()" />
              <label class="custom-control-label" for="exactDateTrue" translate>I have exact date(s)</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input custom-control"
                id="exactDateFalse"
                [checked]="formBuilder.get('exactDate')?.value === false"
                (click)="exactDayIsChecked()" />
              <label class="custom-control-label" for="exactDateFalse" translate>Pick a period</label>
            </div>
            <ng-container formArrayName="dates" *ngIf="exactDate.value == true">
              <div class="wrap-input" *ngFor="let date of dates.controls; let i = index" [formGroupName]="i">
                <div class="separator" *ngIf="i > 0"></div>
                <div class="text-center">
                  <label *ngIf="i > 0">{{ 'Date' | translate }} {{i}}</label>
                </div>
                <app-date-field
                  [ngClass]="{ mustCheck: !isExistingJob }"
                  formControlName="startDate"
                  iconColor="white"></app-date-field>
                <div class="times">
                  <label>Time</label>
                  <div class="form-group-custom-inline">
                    <app-time-field
                      class="startTime"
                      formControlName="startTime"
                      style="width: 50%"
                      iconColor="white"></app-time-field>
                    <app-time-field
                      class="endTime"
                      formControlName="endTime"
                      style="width: 50%"
                      iconColor="white"></app-time-field>
                  </div>
                </div>
              </div>
              <div class="text-center" *ngIf="dates.controls.length > 1">
                <button type="button" (click)="removeDate(dates.controls.length -1)" class="btn btn-remove" translate>
                  Delete
                </button>
              </div>
              <div class="text-center">
                <button type="button" (click)="addDate()" class="btn btn-add" translate>+ Add date</button>
              </div>
            </ng-container>
            <ng-container *ngIf="exactDate.value == false" formArrayName="dates">
              <div class="wrap-input" *ngFor="let date of dates.controls; let i = index" [formArrayName]="i">
                <app-daterange-field
                  iconColor="white"
                  [ngClass]="{ mustCheck: !isExistingJob }"
                  [range]="date"></app-daterange-field>
                <div class="separator"></div>
                <div class="row">
                  <div class="col">
                    <label translate>Total days</label>
                    <div class="input-group">
                      <input class="form-control" type="number" formControlName="days" min="0" max="100" />
                    </div>
                  </div>
                  <div class="col">
                    <label translate>Avg. hours per day</label>
                    <div class="input-group">
                      <input class="form-control" type="number" formControlName="hours_per_day" min="1" max="24" />
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <!-- Fee -->
          <div class="form-fee">
            <div class="separator"></div>
            <label translate>Compensation</label>
            <div class="row" style="margin-top: 10px">
              <div class="col" *ngFor="let feeTypeEl of feeTypes">
                <div style="width: 50%">
                  <label translate>
                    <app-radio [matcher]="matchById" [value]="feeTypeEl" formControlName="feeType"></app-radio>
                    <span style="position: relative; left: 10px">{{ feeTypeEl.name }}</span>
                  </label>
                </div>
              </div>
            </div>
            <ng-container *ngFor="let feeTypeEl of feeTypes">
              <div class="wrap-input" *ngIf=" feeTypeEl.slug==='fee' && formBuilder?.value?.feeType?.slug==='fee'">
                <div class="separator"></div>
                <div class="row">
                  <div class="col">
                    <label translate>Fee</label>
                    <input
                      class="form-control input-euro"
                      style="margin-bottom: 0px"
                      type="number"
                      min="0"
                      max="100000"
                      formControlName="rate" />
                  </div>
                  <div class="col">
                    <label translate>Buyout</label>
                    <input
                      class="form-control input-euro"
                      style="margin-bottom: 0px"
                      type="number"
                      formControlName="extraFees"
                      min="0"
                      max="100000" />
                  </div>
                  <div class="col-12" style="height: max-content; margin: 20px 0px">
                    <label class="d-flex align-items-center mb-1">
                      <app-checkbox class="mr-2" formControlName="negotiable"></app-checkbox>
                      <span translate>Negotiable</span>
                    </label>
                    <i>
                      <p class="text-info" translate>
                        When enabled 'Negotiable', the Talent and you will negotiate about the fees and details. After
                        negotiations you can send the booking request and in the popup change the details.
                      </p>
                    </i>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="feeTypeEl.slug === formBuilder?.value?.feeType?.slug">
                <div class="separator"></div>
                <div class="publishingChannels">
                  <label translate>Publishing channels</label>
                  <app-checkbox-group
                    class="w-100"
                    formControlName="publishingChannels"
                    [ngClass]="{ mustCheck: !isExistingJob }">
                    <label class="d-flex align-items-center">
                      <app-checkbox class="mr-2" value="online"></app-checkbox>
                      Online
                    </label>
                    <label class="d-flex align-items-center">
                      <app-checkbox class="mr-2" value="print"></app-checkbox>
                      Print
                    </label>
                    <label class="d-flex align-items-center">
                      <app-checkbox class="mr-2" value="tv-film"></app-checkbox>
                      TV / Film
                    </label>
                  </app-checkbox-group>
                  <p class="text-info" translate>
                    Assignments booked via Casterbee always have the license for all mediums.
                  </p>
                </div>
                <div class="broadcastCountries">
                  <div class="separator"></div>
                  <label translate>Broadcast countries</label>
                  <app-select
                    [ngClass]="{ mustCheck: !existJob != null ? true : false }"
                    formControlName="broadcastCountries"
                    iconColor="white">
                    <app-option class="d-flex w-100 py-1 align-items-center text-white" translate value="worldwide">
                      Worldwide
                    </app-option>
                    <app-option class="d-flex w-100 py-1 align-items-center text-white" translate value="other">
                      Defined countries
                    </app-option>
                  </app-select>
                  <div
                    style="margin-top: 22px"
                    *ngIf="otherBroadcastCountriesSelected()"
                    formArrayName="licenceCountries">
                    <div
                      *ngFor="let licenceCountry of licenceCountries.controls; let i = index"
                      [formGroupName]="i"
                      class="location-{{ i }}"
                      style="margin-bottom: 22px">
                      <div *ngIf="dataCountries.length>0">
                        <app-select
                          [searchable]="true"
                          class="w-100 rounded-sm mr-2"
                          formControlName="id"
                          iconColor="white">
                          <app-option
                            *ngFor="let country of dataCountries"
                            [value]="country.id"
                            [searchValue]="country.translateKey | translate"
                            class="d-flex w-100 py-1 align-items-center">
                            <img
                              class="flag mr-2"
                              src="https://storage.googleapis.com/casterbee-public/img/flags/{{
                                            country.isoCode.toLowerCase()
                                          }}.svg"
                              [alt]="country.id" />
                            {{ country.translateKey | translate }}
                          </app-option>
                        </app-select>
                      </div>
                    </div>
                    <div class="text-center" style="margin-bottom: 22px" *ngIf="licenceCountries.controls.length > 0">
                      <button
                        type="button"
                        (click)="removeLicenceCountry(licenceCountries.controls.length -1)"
                        class="btn btn-remove"
                        translate>
                        Remove
                      </button>
                    </div>
                    <div class="text-center">
                      <button type="button" (click)="addLicenceCountry()" class="btn btn-add" translate>
                        + Add countries
                      </button>
                    </div>
                  </div>
                </div>
                <div class="licenceDuration">
                  <div class="separator"></div>
                  <label translate>License duration</label>
                  <div class="row">
                    <div class="col">
                      <app-select
                        formControlName="licenceDuration"
                        [ngClass]="{ mustCheck: !isExistingJob }"
                        iconColor="white">
                        <app-option class="d-flex w-100 py-1 align-items-center text-white" translate value="forever">
                          Forever
                        </app-option>
                        <app-option class="d-flex w-100 py-1 align-items-center text-white" translate value="other">
                          Defined period
                        </app-option>
                      </app-select>
                    </div>
                    <div class="col">
                      <div *ngIf="otherLicenceDurationSelected()">
                        <input
                          type="text"
                          class="form-control"
                          formControlName="licenceDurationCustom"
                          [readOnly]="!otherLicenceDurationSelected()" />
                      </div>
                    </div>
                  </div>
                  <div *ngIf="otherLicenceDurationSelected()" style="margin-top: 10px">
                    <p class="text-info" translate>Define a custom period the licence is valid</p>
                  </div>
                </div>
                <div class="commercialUse">
                  <div class="separator"></div>
                  <label translate>Commerial use</label>
                  <app-select class="w-100 rounded-sm" formControlName="commercialUse" iconColor="white">
                    <app-option class="d-flex w-100 py-1 align-items-center text-white" translate [value]="true">
                      Yes
                    </app-option>
                    <app-option class="d-flex w-100 py-1 align-items-center text-white" translate [value]="false">
                      No
                    </app-option>
                  </app-select>
                  <p class="text-info" style="font-style: italic" translate>
                    Commercial uses gives you the permission to use the footage for commercial purposes, for example to
                    promote your business or product.
                  </p>
                </div>
                <div class="foodIncluded" style="margin-top: 10px">
                  <label translate>Lunch / dinner included?</label>
                  <div class="row">
                    <div class="col-6 d-flex align-items-center">
                      <label class="mr-4 d-flex align-items-center">
                        <app-radio [value]="false" class="mr-2" formControlName="foodIncluded"></app-radio>
                        <span translate>No</span>
                      </label>
                      <label class="d-flex align-items-center">
                        <app-radio [value]="true" class="mr-2" formControlName="foodIncluded"></app-radio>
                        <span translate>Yes</span>
                      </label>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
          <!-- Traveling Cost -->
          <div class="haveTravelingCost">
            <div class="separator"></div>
            <label translate>Traveling cost</label>
            <div class="row">
              <div class="col">
                <div style="width: 50%">
                  <label>
                    <app-radio
                      class="mr-2 haveTravelCost"
                      [ngClass]="{ mustCheck: !existJob }"
                      [value]="false"
                      formControlName="haveTravelingCost"></app-radio>
                    <span translate>No</span>
                  </label>
                </div>
              </div>
              <div class="col">
                <div style="width: 50%">
                  <label>
                    <app-radio
                      class="mr-2 haveTravelCost"
                      [ngClass]="{ mustCheck: !existJob }"
                      [value]="true"
                      formControlName="haveTravelingCost"></app-radio>
                    <span translate>Yes</span>
                  </label>
                </div>
              </div>
            </div>
            <div *ngIf="formBuilder.get('haveTravelingCost')?.value == true">
              <div class="row mt-2">
                <div class="col">
                  <label translate>Per KM</label>
                  <input type="number" formControlName="travelingCost" class="form-control" step="0.01" />
                </div>
                <div class="col">
                  <label class="mr-4 d-flex align-items-center">
                    <app-checkbox
                      class="mr-2"
                      formControlName="maxTravelingCost"
                      [trueValue]="0"
                      [falseValue]="null"
                      [checked]="formBuilder.get('maxTravelingCost')?.value != null"></app-checkbox>
                    <span translate>Max. amount</span>
                  </label>
                  <input
                    type="number"
                    formControlName="maxTravelingCost"
                    [readOnly]="formBuilder.get('maxTravelingCost')?.value != null ? false : true"
                    class="form-control" />
                </div>
              </div>
            </div>
            <div *ngIf="formBuilder.get('travelingCost')?.value != null">
              <div class="separator"></div>
              <label translate>Parking cost</label>
              <div class="row">
                <div class="col">
                  <div style="width: 50%">
                    <label>
                      <app-radio class="mr-2" [value]="null" formControlName="parkingCost"></app-radio>
                      <span translate>No</span>
                    </label>
                  </div>
                </div>
                <div class="col">
                  <div style="width: 50%">
                    <label>
                      <app-radio class="mr-2" [value]="true" formControlName="parkingCost"></app-radio>
                      <span translate>Yes</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="formBuilder.get('parkingCost')?.value == true" class="mt-2">
              <label class="mr-4 d-flex align-items-center">
                <app-checkbox
                  class="mr-2"
                  formControlName="maxParkingCost"
                  [trueValue]="'0.1'"
                  [falseValue]="null"
                  [checked]="formBuilder.get('maxParkingCost')?.value != null"></app-checkbox>
                <span translate>Max. amount</span>
              </label>
            </div>
            <div
              *ngIf="formBuilder.get('travelingCost')?.value != null && formBuilder.get('maxParkingCost')?.value != null">
              <input
                type="number"
                formControlName="maxParkingCost"
                [readOnly]="formBuilder.get('maxParkingCost')?.value != null ? false : true"
                class="form-control"
                style="width: 50%"
                step="0.01" />
            </div>
            <div class="separator"></div>
          </div>
          <!-- Description -->
          <div class="shortDescription>">
            <label translate>Shoot description</label>
            <textarea formControlName="shortDescription" class="textarea-control" cols="30" rows="10"></textarea>
            <div class="separator"></div>
          </div>
          <ng-container *ngIf="!isDirect">
            <!-- Gender -->
            <div>
              <label translate>Gender</label>
              <app-checkbox-group formControlName="gender">
                <label class="d-flex align-items-center">
                  <app-checkbox class="mr-2" value="male"></app-checkbox>
                  <span translate>Male</span>
                </label>
                <label class="d-flex align-items-center">
                  <app-checkbox class="mr-2" value="female"></app-checkbox>
                  <span translate>Female</span>
                </label>
                <label class="d-flex align-items-center">
                  <app-checkbox class="mr-2" value="transgender"></app-checkbox>
                  <span translate>Transgender</span>
                </label>
              </app-checkbox-group>
              <div class="separator"></div>
            </div>
            <!-- Age -->
            <div class="age">
              <label translate>Age</label>
              <app-slider [options]="{ floor: 18, ceil: 100 }" formControlName="age"></app-slider>
              <div class="separator"></div>
            </div>
            <!-- Video -->
            <div class="video">
              <label translate>Request video</label>
              <div class="row">
                <div class="col">
                  <div style="width: 50%">
                    <label class="d-flex align-items-center">
                      <app-radio [value]="false" class="mr-2" formControlName="requestTalentVideo"></app-radio>
                      <span translate>No</span>
                    </label>
                  </div>
                </div>
                <div class="col">
                  <div style="width: 50%">
                    <label class="mr-4 d-flex align-items-center">
                      <app-radio [value]="true" class="mr-2" formControlName="requestTalentVideo"></app-radio>
                      <span translate>Yes</span>
                    </label>
                  </div>
                </div>
              </div>
              <textarea
                *ngIf="requestTalentVideo.value === true"
                formControlName="requestTalentVideoDescription"
                class="textarea-control"
                cols="30"
                rows="10"></textarea>
              <div class="separator"></div>
            </div>
            <!-- Filter -->
            <!-- <div class="filters">
              <div class="text-center">
                <button type="button" (click)="toggleAdditionalOptions()" type="button" class="btn btn-add" translate>
                  Filter options
                </button>
              </div>
              <div *ngIf="showAdditionalOptions">
                <label translate>Language</label>
                <p class="text-info" translate>Which language(s) do you want your model to speak?</p>
                <div formArrayName="languages">
                  <div
                    *ngFor="let language of languages.controls; let i = index"
                    [formGroupName]="i"
                    class="language-{{ i }}">
                    <div *ngIf="languages$ | async as languageOptions">
                      <app-select formControlName="id" iconColor="white" [searchable]="true">
                        <app-option
                          *ngFor="let item of languageOptions?.results"
                          [value]="item.id"
                          [searchValue]="item.translateKey | translate"
                          class="d-flex w-100 py-1 align-items-center">
                          {{ item.translateKey | translate }}
                        </app-option>
                      </app-select>
                    </div>
                  </div>
                  <div class="text-center" *ngIf="languages.controls.length > 0">
                    <button
                      type="button"
                      (click)="removeLanguage(languages.controls.length -1)"
                      class="btn btn-remove"
                      translate>
                      Delete
                    </button>
                  </div>
                  <div class="text-center">
                    <button type="button" (click)="addLanguage()" class="btn btn-add" translate>Add Languange</button>
                  </div>
                </div>
                <div
                  *ngIf="categorySelected !== null && categorySelected !== undefined && filterCategories.length > 0 && !!categorySelected"
                  formGroupName="filters">
                  <div class="separator"></div>
                  <div *ngFor="let categoryFilter of filterCategories">
                    <label class="text-bold" translate>{{ 'FILTER.' + categoryFilter.slug | translate }}</label>
                    <div *ngIf="categoryFilter.type === 'checkbox'">
                      <app-checkbox-group [formControlName]="categoryFilter.slug" class="row w-100">
                        <label
                          *ngFor="let option of categoryFilter.filterOptions"
                          class="col-6 col-sm-4 my-1 d-flex align-items-center">
                          <app-checkbox [value]="option.id" class="mr-2" class="mr-2"></app-checkbox>
                          {{ option.translateKey | translate }}
                        </label>
                      </app-checkbox-group>
                    </div>
                    <div *ngIf="categoryFilter.type === 'slider'">
                      <app-weight-slider
                        *ngIf="categoryFilter.unit === 'kg'"
                        [formControlName]="categoryFilter.slug"
                        [options]="{ floor: categoryFilter.min, ceil: categoryFilter.max }"></app-weight-slider>

                      <app-metric-slider
                        *ngIf="categoryFilter.unit === 'cm'"
                        [formControlName]="categoryFilter.slug"
                        [options]="{ floor: categoryFilter.min, ceil: categoryFilter.max }"></app-metric-slider>
                    </div>
                    <div *ngIf="categoryFilter.type === 'color'">
                      <app-color-slider [formControlName]="categoryFilter.slug"></app-color-slider>
                    </div>
                    <input
                      *ngIf="categoryFilter.type === 'number'"
                      type="number"
                      class="form-control"
                      [formControlName]="categoryFilter.slug" />
                    <input
                      *ngIf="categoryFilter.type === 'text'"
                      type="text"
                      class="form-control"
                      [formControlName]="categoryFilter.slug" />
                  </div>
                </div>
              </div>
              <div class="separator"></div>
            </div> -->
          </ng-container>
          <!-- Media -->
          <div class="mediaItems" formArrayName="media">
            <div class="mediaItems">
              <div
                *ngFor="let mediaItem of media.controls; let i = index"
                [formGroup]="mediaItem"
                [style.background-image]="mediaItem.get('url').value != null ? 'url(' + mediaItem.get('url').value + ')' : ''"
                class="media">
                <div class="overlay">
                  <app-media-field
                    accept="image/png,image/x-png,image/gif,image/jpeg"
                    formControlName="file"></app-media-field>
                  <button class="btn-delete-img" type="button" (click)="removeMedia(i)">
                    <img src="/assets/img/mobile/trash.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>
            <div class="text-center">
              <button
                type="button"
                (click)="addMedia()"
                *ngIf="media.controls.length < 9"
                class="btn btn-add"
                translate>
                + Add media
              </button>
              <p class="text-info" translate>Upload a photo or video</p>
            </div>
            <div class="separator"></div>
          </div>
          <!-- Promoted -->
          <div class="promotedJobs" *ngIf="!isDirect">
            <label style="margin-bottom: 5px; display: block" translate>Promoted</label>
            <p class="text-info" style="font-style: italic" translate>
              Posting a job is free of any additional charge. But if you want your job to really stand out from the
              rest, you can boost it for only € 9.
            </p>
            <label style="margin-top: 10px" class="w-100 d-flex align-items-center" translate>
              <app-checkbox
                class="mr-2"
                formControlName="promoted"
                [isReadOnly]="job?.payment?.paymentStatus == 'completed'"
                [checked]="job?.promoted"></app-checkbox>
              Yes
            </label>
            <div class="separator"></div>
          </div>
          <!-- Total Const -->
          <div class="totalCost">
            <label translate>Total costs incl. VAT</label>
            <input
              type="text"
              readonly="true"
              class="form-control input-euro input-readonly"
              style="width: 50%"
              [value]="this.talentPaymentCosts | currency: 'EUR' : ''" />
            <p class="text-info" style="font-style: italic" translate>
              The fee you have to pay when you select a Talent.
            </p>
            <div class="separator"></div>
          </div>
          <!-- VAT -->
          <div class="">
            <label translate>VAT</label>
            <input
              type="text"
              readonly="true"
              class="form-control input-euro input-readonly"
              style="width: 50%"
              [value]="this.talentPaymentVAT | currency: 'EUR' : ''" />
            <input
              type="text"
              readonly="true"
              class="form-control input-euro input-readonly"
              style="width: 50%"
              [value]="this.directPaymentCosts | currency: 'EUR' : ''" />
            <p class="text-info" style="font-style: italic" translate>
              The fee you must pay directly when posting this job.
            </p>
            <div class="separator"></div>
          </div>
          <!-- Discount -->
          <div class="discountCode">
            <label translate>Discount code</label>
            <input type="text" class="form-control" formControlName="budgetCamOrderNumber" style="width: 50%" />
          </div>
        </div>
        <div class="modal-footer">
          <div class="wrap-alert text-center" *ngIf="message?.type && message?.type == 'error'">
            <app-alert [type]="message?.type">{{ message?.message }}</app-alert>
          </div>
          <ng-container *ngIf="isDirect">
            <button
              *ngIf="needsPayment && job?.payment?.paymentStatus != 'completed'; else noPaymentDirect"
              type="submit"
              class="btn-submit"
              [disabled]="!formBuilder.valid">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isBusy"></span>
              <span *ngIf="!isBusy">{{ "Offer job" | translate }}</span>
              <span *ngIf="!isBusy">{{'and create payment' | translate}}</span>
            </button>
            <ng-template #noPaymentDirect>
              <button type="submit" class="btn-submit" [disabled]="!formBuilder.valid">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isBusy"></span>
                <span *ngIf="!isBusy">{{ "Offer job" | translate }}</span>
              </button>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="!isDirect">
            <button type="submit" class="btn-submit" [disabled]="!formBuilder.valid">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isBusy"></span>
              <span
                *ngIf="!isBusy && !existJob && needsPayment && job?.payment?.paymentStatus != 'completed'; else noPaymentNonDirect">
                {{ "Save Job and create payment" | translate }}
              </span>
              <ng-template #noPaymentNonDirect>
                <span *ngIf="!isBusy" translate>Save Job</span>
              </ng-template>
            </button>
          </ng-container>
          <button type="button" (click)="close()" class="btn btn-block" translate>Cancel</button>
        </div>
      </form>
    </div>
  </ng-template>
</app-mobile-modal-dialog>
