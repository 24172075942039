<app-mobile-modal-dialog #appRequestToBookModal [backgroundColor]="'rgb(123 123 123 / 10%)'">
  <ng-container *ngIf="!isSuccess else success">
    <div class="mobile-request-book-modal">
      <ng-container *ngIf="!isEditFee else editFeeContainer">
        <div class="modal-body" style="max-height: unset !important">
          <div class="request-tobook-info">
            <h6>{{'Request to Book' | translate}}</h6>
            <div style="margin-bottom: 20px" *ngIf="jobApplication" class="booked-user-data">
              <div class="booked-user-photo">
                <img [src]="jobApplication.user.photo?.thumbUrl" alt="" />
              </div>
              {{jobApplication.user.firstName}} {{jobApplication.user.lastName}}
            </div>
            <p class="text-center" translate>You are about to send a booking request</p>
            <ol class="mt-3">
              <li translate>The Talent can accept or reject your booking request.</li>
              <li translate>
                If you cancel a confirmed booking within 3 days before the shoot date, the Talent may charge 50% of the
                fee, 100% of the fee if canceled within 1 day before the shoot date.
              </li>
            </ol>
          </div>
        </div>
        <div class="modal-footer">
          <div class="wrap-btn mb-2">
            <button
              type="button"
              class="btn btn-bg-purple"
              style="border-radius: 100px; margin: auto"
              [ngStyle]="{width:isBusy?'182px':'max-content'}"
              (click)="acceptJobApplication()">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isBusy"></span>
              <span *ngIf="!isBusy">{{ 'Yes send Now' | translate }}</span>
            </button>
          </div>
          <button type="button" (click)="showEditFee(true)" class="btn btn-bg-purple">
            {{'Edit fee' | translate}}
          </button>
        </div>
      </ng-container>
      <ng-template #editFeeContainer>
        <ng-container *ngIf="!submitConfirmation">
          <div class="modal-body" style="max-height: unset !important">
            <div class="request-tobook-info">
              <h6>{{'Edit Fee' | translate}}</h6>
              <div *ngIf="jobApplication" class="booked-user-data">
                <div class="booked-user-photo">
                  <img [src]="jobApplication.user.photo?.thumbUrl" alt="" />
                </div>
                {{jobApplication.user.firstName}} {{jobApplication.user.lastName}}
              </div>
              <form [formGroup]="formBuilder">
                <div class="control-group">
                  <div class="control-container">
                    <label class="form-label" translate>Total fee:</label>
                    <input
                      type="text"
                      class="form-control input-euro"
                      appCommaToDot="numberAndDot"
                      formControlName="totalFee" />
                  </div>
                  <div class="control-container">
                    <label class="form-label" translate>Total buy-out:</label>
                    <input
                      type="text"
                      class="form-control input-euro"
                      appCommaToDot="numberAndDot"
                      formControlName="totalBuyout" />
                  </div>
                </div>
                <div class="control-container with-divider">
                  <label class="form-label strong" translate>Traveling Cost</label>
                  <div class="w-full">
                    <div class="checkbox-group">
                      <label (click)="onChangeTravelCost(true)">
                        <app-radio
                          class="mr-2"
                          isReadOnly="true"
                          [value]="true"
                          formControlName="travelCost"></app-radio>
                        <span translate>Yes</span>
                      </label>
                      <label (click)="onChangeTravelCost(false)">
                        <app-radio
                          class="mr-2"
                          isReadOnly="true"
                          [value]="false"
                          formControlName="travelCost"></app-radio>
                        <span translate>No</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div *ngIf="formValue?.travelCost" class="control-group with-divider">
                  <div class="control-container">
                    <label class="form-label" translate>Per KM</label>
                    <input
                      type="text"
                      appCommaToDot="numberAndDot"
                      class="form-control input-euro"
                      formControlName="travelCostAmount" />
                  </div>
                  <div class="control-container">
                    <div class="form-label">
                      <label (click)="changeControlValue('travelCost')">
                        <app-radio
                          class="mr-2"
                          [value]="true"
                          formControlName="travelCostMax"
                          isReadOnly="true"></app-radio>
                        <span translate>Max ammount</span>
                      </label>
                    </div>
                    <input
                      type="text"
                      appCommaToDot="numberAndDot"
                      class="form-control input-euro"
                      formControlName="travelCostMaxAmount" />
                  </div>
                </div>
                <div style="margin-top: 30px" class="control-container with-divider">
                  <label class="form-label strong" translate>Parking Cost</label>
                  <div class="checkbox-group">
                    <label>
                      <app-radio class="mr-2" [value]="true" formControlName="parkingCost"></app-radio>
                      <span translate>Yes</span>
                    </label>
                    <label>
                      <app-radio class="mr-2" [value]="false" formControlName="parkingCost"></app-radio>
                      <span translate>No</span>
                    </label>
                  </div>
                </div>
                <div *ngIf="formValue?.parkingCost" class="control-group with-divider">
                  <div class="control-container">
                    <div class="form-label">
                      <label (click)="changeControlValue('parkingCost')">
                        <app-radio
                          class="mr-2"
                          [value]="true"
                          formControlName="parkingCostMax"
                          isReadOnly="true"></app-radio>
                        <span translate>Max ammount</span>
                      </label>
                    </div>
                    <input
                      type="text"
                      appCommaToDot="numberAndDot"
                      class="form-control input-euro"
                      formControlName="parkingCostMaxAmount" />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <div class="wrap-btn mb-2">
              <button
                type="button"
                class="btn btn-bg-purple"
                [ngStyle]="{width:isBusy?'182px':'max-content'}"
                (click)="setSubmitConfirmation(true)">
                {{ 'Send' | translate }}
              </button>
            </div>
            <button type="button" (click)="showEditFee(false)" class="btn btn-block btn-grey">
              {{'Cancel' | translate}}
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="submitConfirmation">
          <div
            class="modal-body"
            style="max-height: unset !important; display: flex; align-items: center; justify-content: center">
            <p>Are you sure ?</p>
          </div>
          <div class="modal-footer">
            <div class="wrap-btn mb-2">
              <button
                type="button"
                class="btn btn-bg-purple"
                [disabled]="isBusy"
                style="border-radius: 100px; margin: auto"
                [ngStyle]="{width:isBusy?'182px':'max-content'}"
                (click)="submitEditFee()">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isBusy"></span>
                <span *ngIf="!isBusy">{{ 'Yes' | translate }}</span>
              </button>
            </div>
            <button
              [disabled]="isBusy"
              type="button"
              (click)="setSubmitConfirmation(false)"
              class="btn btn-block btn-grey">
              {{'Cancel' | translate}}
            </button>
          </div>
        </ng-container>
      </ng-template>
    </div>
  </ng-container>
  <ng-template #success>
    <div class="modal-body success-boook-information" style="max-height: unset!important; height: calc(100vh - 106px) !important">
      <img src="/assets/img/mobile/success.svg" alt="" />
      <h6>
        Booking request has been sent
      </h6>
      <p translate style="margin-bottom: 0;">
        Great! A booking request has been send. Please don't forget to reject the other Talents. This higher the
        chances that they will apply to your job the next time.
      </p>
      <img src="/assets/img/reject-talents-meme-2.png" alt="" style="width: 250px;" />
      <div class="wrap-btn mt-t" style="padding-bottom: 50px;">
        <button type="button" (click)="close()" class="btn btn-block close-button">{{'Close' | translate}}</button>
      </div>
    </div>
  </ng-template>
</app-mobile-modal-dialog>
