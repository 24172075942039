<ng-container *ngIf="progress > 0 && isUploading; else showForm">
  <div class="w-100 p-2 m-auto">
    <div class="progress mb-3" style="height: 30px">
      <div
        class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        *ngIf="progress < 100"
        [ngStyle]="{ width: progress + '%' }"
        aria-valuenow="{{progress}}"
        aria-valuemin="0"
        aria-valuemax="100">
        {{progress}}%
      </div>
      <div
        class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        *ngIf="progress >= 100"
        style="width: 100%"
        aria-valuenow="100"
        aria-valuemin="0"
        aria-valuemax="100">
        Processing video transcoding
      </div>
    </div>
  </div>
</ng-container>
<ng-template #showForm>
  <div class="p-1 d-flex">
    <app-image
      *ngIf="thumbUrl$ | async as thumbUrl"
      [image]="thumbUrl"
      [ngClass]="{ isVideo: value.mediaType == 'video' }"
      [lazy]="false"></app-image>
    <div class="profile-photo-label postion-absolute" *ngIf="isProfilePhoto" translate>Profile photo</div>
    <div class="overlay" [ngClass]="{ isVideo: value?.mediaType == 'video' }">
      <div class="upload-icon" *ngIf="value?.mediaType == 'video' && !value.uploaded && !isUploading">
        <fa-icon [styles]="{ color: '#FFFFFF' }" [icon]="uploadIcon"></fa-icon>
      </div>
      <app-media-field [accept]="accept" class="mt-auto mr-auto" [formControl]="fileControl"></app-media-field>
    </div>

    <div class="order-button-container p-2 d-flex flex-row" *ngIf="allowSorting">
      <div
        *ngIf="value.order !== 1"
        (click)="moveUp.emit()"
        class="order-button align-items-center justify-content-center d-flex mr-2">
        <fa-icon [styles]="{ color: '#FFFFFF' }" [icon]="chevronUpIcon"></fa-icon>
      </div>
      <div (click)="moveDown.emit()" class="order-button align-items-center justify-content-center d-flex">
        <fa-icon [styles]="{ color: '#FFFFFF' }" [icon]="chevronDownIcon"></fa-icon>
      </div>
    </div>
    <div
      (click)="delete.emit()"
      class="delete"
      [ngClass]="{ 'del-video': value?.mediaType == 'video' }"
      *ngIf="!disableDelete">
      <app-icon fill="white" icon="/assets/img/trash.svg"></app-icon>
    </div>

    <input
      class="form-control youtube-link-field mt-2"
      *ngIf="value?.mediaType == 'video'"
      [placeholder]="'Youtube-link' | translate"
      type="text"
      [value]="videoUrl"
      (paste)="onPasteUrl($event)"
      (blur)="updateVideoUrl($event.target.value)" />
  </div>
</ng-template>
