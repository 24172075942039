<div class="mobile-job-rows-list-container">
  <div
    class="mobile-job-rows"
    [ngClass]="getGradientBg(job.totalRate)"
    (click)="openModal(job)"
    *ngFor="let job of jobs; let i = index">
    <div *ngIf="promoted" style="display: flex; gap: 5px; align-items: center">
      <img src="/assets/img/star-icon.svg" alt="" />
      <span>Promoted</span>
    </div>
    <div class="job-title">
      <div *ngIf="job.media.length >= 1 else alterantiveImage" class="job-title-img-container">
        <img [src]="job.media[0].url" alt="" />
      </div>
      <ng-template #alterantiveImage>
        <div class="job-title-img-container alt-img-container">
          <img
            class="alternative-image"
            [src]="('/assets/img/categories/icons/' + job.category.slug + '.svg')"
            alt="" />
        </div>
      </ng-template>
      <p>{{job.title}}</p>
    </div>
    <div class="job-desc">
      <p *ngIf="!job.hideRate" style="max-width: unset">
        <span class="price-tag">€</span>
        &nbsp; {{ job.totalRate | number }}
        <span translate class="font-22 font-regular" *ngIf="job.negotiable">
          &nbsp; | &nbsp; {{ 'Negotiable' | translate }}
        </span>
      </p>
      <p *ngIf="(!job.hideRate || (userAlreadyApplied$ | async) === true)">
        Buyout
        <app-money
          *ngIf="job.extraFees; else noResults"
          [amount]="job.extraFees"
          class="h3 flex-grow-1 text-break"></app-money>
        <span #buyoutIncluded translate>Included</span>
      </p>
      <p>{{ job.category.label }}</p>
      <p>
        <span *ngIf="job?.formattedGenders.length >= 3; else genderList">{{'All Gender' | translate}}</span>
        <ng-template #genderList>
          <span *ngFor="let gender of job?.formattedGenders; i as index" translate class="mr-1">{{ gender }},</span>
        </ng-template>
      </p>
      <p>{{ job.firstDate.start | date: "dd MMM" }} - {{ job.firstDate.end | date: "dd MMM" }}</p>
      <p *ngIf="job.countries[0]?.city || job.countries[0]?.country?.isoCode">
        <ng-container *ngIf="job.countries[0]?.city">{{ job.countries[0]?.city }},</ng-container>
        {{ job.countries[0]?.country?.isoCode }}
      </p>
    </div>
  </div>
  <!-- <div *ngIf="promoted" class="job-list-divider"></div> -->
</div>
