<div class="d-flex flex-column w-100 text-white p-4" *ngIf="!isAllowedToApply">
  <h2 translate>Applying is not possible</h2>
  <p class="mt-3 mb-0" translate>Your profile falls outside the criteria. Possible reasons:</p>
  <p class="mb-0" translate>- Caster is looking for a different gender</p>
  <p class="mb-0" translate>- For example, the job is for models, but you are an actor</p>
  <p class="mb-0" translate>- You are outside the age category</p>
  <app-button (click)="closeModal()" buttonStyle="white-outline" class="mb-3 mt-3">
    <div class="px-2" translate>Close</div>
  </app-button>
</div>

<div
  class="d-flex flex-column w-100 text-white p-5"
  *ngIf="!requireVideo && applied && isAllowedToApply; else applyJobTemplate">
  <h2 translate>You applied to the job.</h2>
  <p translate>When the Caster accepts your application you will get a notification.</p>
  <app-button (click)="closeModal()" buttonStyle="white-outline" class="mb-3">
    <div class="px-2" translate>Close</div>
  </app-button>
</div>

<ng-template #applyJobTemplate>
  <ng-container *ngIf="jobQuota$ | async as jobQuota">
    <ng-container *ngIf="jobQuota.available > 0 || jobQuota.unlimited">
      <ng-container *ngIf="!requireNote; else noteTemplate">
        <div class="d-flex flex-column w-100 text-white p-4" *ngIf="!applied && !requireVideo && isAllowedToApply">
          <h2 translate>APPLY TO JOB?</h2>
          <p class="mt-2" translate>Great, you almost applied! The Caster can book, reject or send you a message.</p>
          <p translate>When accepted, you have a binding agreement with the Caster about this specific assignment.</p>
          <p translate>
            Casters are encouraged to reject Talents on time. If you haven't received info 3 days prior shoot you may
            assume you are not booked.
          </p>
          <p translate *ngIf="haveFreeMembership">
            15% commission will be deducted from the published price with a free profile, upgrade your membership to
            reduce this to less or even 0%.
          </p>
          <p *ngIf="!jobQuota.unlimited" translate>
            <b [innerHTML]="'jobApplicationLeft' | translate: { quota: jobQuota.available }"></b>
          </p>
          <app-button (click)="applyJob()" buttonStyle="artyclick-purple" class="mb-3">
            <div class="px-2" translate>Apply</div>
          </app-button>
          <app-button (click)="closeModal()" buttonStyle="white-outline" class="mb-3">
            <div class="px-2" translate>Cancel</div>
          </app-button>
          <p translate>When applied, the job shows at your applied jobs in the dashboard</p>
        </div>

        <ng-container *ngIf="requireVideo && isAllowedToApply">
          <app-job-application-upload-audition-video
            *ngIf="job && requireVideo"
            [job]="job"
            [enableWrapper]="false"
            [applyModal]="true"
            (applyClicked)="videoAction($event)"
            [userId]="user.id"></app-job-application-upload-audition-video>
        </ng-container>
      </ng-container>
      <ng-template #noteTemplate>
        <app-note-input (applyClicked)="submitApplyJob($event)" [negotiable]="job.negotiable"></app-note-input>
      </ng-template>
    </ng-container>
    <div
      class="d-flex flex-column w-100 text-white p-4"
      *ngIf="jobQuota.available === 0 && !jobQuota.unlimited && isAllowedToApply">
      <h3 class="text-center" translate>Oh no! You've run out of credits</h3>
      <p class="mt-3 credit-explanation text-center" translate>
        You need 1 credit to apply to this job. Quickly buy credits or get a membership below.
      </p>
      <div class="d-flex flex-column mb-3 mt-3 align-items-center">
        <ng-container *ngIf="creditPackages$ | async as creditPackages">
          <app-solid-button
            *ngFor="let creditPackage of creditPackages;"
            (click)="buyCredit(creditPackage)"
            buttonStyle="white"
            [isBusy]="isProcessBuyCredits"
            [isDisabled]="isProcessBuyCredits"
            class="d-flex update-quota-btn">
            <div
              class="px-2 buy-credit-labels"
              [innerHTML]="'buyCreditsButton' | translate: { price: creditPackage.price, amount: creditPackage.amount }"
              translate></div>
          </app-solid-button>
        </ng-container>
        <app-solid-button
          (click)="upgradeMembership()"
          buttonStyle="artyclick-purple"
          class="d-flex update-quota-btn mt-3">
          <div class="px-2" translate>Upgrade membership</div>
        </app-solid-button>
      </div>

      <div class="cancel-button text-center cursor-pointer" (click)="closeModal()" translate>Cancel</div>
    </div>
  </ng-container>
</ng-template>
