import { Component, Inject, OnDestroy, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ICountry, IFeeType, IUser } from '@core/interfaces';
import { CountriesState, FeeTypesState, SaveJob, UserState } from '@core/states';
import { Observable, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { v4 } from 'uuid';
import { Category, Package, User } from '@core/models';
import { environment } from '@environments/environment';
import moment from 'moment';
import { SuccessCreateJobModalComponent } from '../success-create-job-modal/success-create-job-modal.component';
@Component({
  selector: 'app-offer-job-modal',
  templateUrl: './offer-job-modal.component.html',
  styleUrls: ['./offer-job-modal.component.scss'],
})
export class OfferJobModalComponent implements OnDestroy {
  @Select(CountriesState.countries)
  public countries$: Observable<ICountry[]>;

  @Select(UserState.user)
  public currentUser$: Observable<IUser>;

  public form: FormGroup = new FormGroup({
    title: new FormControl(null, [Validators.required]),
    shortDescription: new FormControl(null, [Validators.required]),
    rate: new FormControl(null, [Validators.required, Validators.min(15), Validators.pattern(/^[0-9]*\.?[0-9]*?$/)]),
    // extraFees: new FormControl(null, [Validators.pattern(/^[0-9]*\.?[0-9]*?$/)]),
    tc: new FormControl(true),
    tcAmount: new FormControl(0.23, [Validators.pattern(/^[0-9]*\.?[0-9]*?$/)]),
    isTcMaxAmount: new FormControl(false),
    tcMaxAmount: new FormControl(null, [Validators.pattern(/^[0-9]*\.?[0-9]*?$/)]),
    pc: new FormControl(false),
    isPcMaxAmount: new FormControl(false),
    pcMaxAmount: new FormControl(null, [Validators.pattern(/^[0-9]*\.?[0-9]*?$/)]),
  });

  public caster: User;
  public talent: User;
  public package: Package;
  public category: Category;
  public isProcess = false;
  private subscription: Subscription;

  public constructor(
    @Optional() private dialogRef: MatDialogRef<OfferJobModalComponent>,
    @Inject(MAT_DIALOG_DATA) @Optional() public data: any,
    private store: Store,
    private dialog: MatDialog,
  ) {
    this.fetchCaster();
    this.talent = data.talent;
    this.package = data.package;
    this.category = data.category;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public async submit(): Promise<void> {
    if (this.form.invalid || this.isProcess) {
      return;
    }

    this.isProcess = true;

    try {
      const cleanPath = window.location.pathname.replace('/job-thank-you', '');
      const returnUrl = `${environment.baseUrl}${cleanPath}/job-process-payment/[PAYMENT_ID]${window.location.search}`;
      const jobNeedsPayment = !this.caster.bypassPayment;
      const paymentTypeId = '15a42529-95ef-4ca5-b923-97b70b456016';
      const dates = moment().format('YYYY-MM-DD HH:mm:ss');
      const jobId = v4();
      const feeType = this.store
        .selectSnapshot(FeeTypesState.feeTypes)
        .filter((feeTypes: IFeeType): boolean => feeTypes.slug === 'fee');

      const data: any = {
        id: jobId,
        title: this.form.get('title')?.value,
        shortDescription: this.form.get('shortDescription')?.value,
        rate: this.form.get('rate')?.value,
        extraFees: this.form.get('extraFees')?.value,
        travelingCost: this.form.get('tc')?.value ? this.form.get('tcAmount')?.value : null,
        maxTravelingCost: this.form.get('isTcMaxAmount')?.value ? this.form.get('tcMaxAmount')?.value : null,
        parkingCost: this.form.get('pc')?.value,
        maxParkingCost: this.form.get('isPcMaxAmount')?.value ? this.form.get('pcMaxAmount')?.value : null,
        isDirectOffer: true,
        paymentTypeId,
        startAge: this.talent.age,
        endAge: this.talent.age,
        userId: this.caster.id,
        categoryId: this.category.id,
        exactDate: true,
        dates: [
          {
            id: v4(),
            start: dates,
            end: dates,
          },
        ],
        feeType,
        gender: this.talent.gender,
        commercialUse: true,
        packageId: this.package?.id,
        broadcastCountries: 'worldwide',
        licenceDuration: '1 jaar',
        publishingChannels: 'print',
        countries: [
          {
            countryId: this.talent.country.id,
            city: this.talent.country.name,
            coordinates: {
              x: this.talent.country.coordinates.x,
              y: this.talent.country.coordinates.y,
            },
            jobId,
          },
        ],
      };

      await this.store.dispatch(new SaveJob(data, this.data.talent?.id, returnUrl, jobNeedsPayment)).toPromise();
      this.isProcess = false;
      if (!jobNeedsPayment) {
        this.dialog.open(SuccessCreateJobModalComponent, {
          data: {
            jobId,
          },
        });
      }

      this.closeModal();
    } catch (error) {
      this.isProcess = false;
      console.log(error);
    }
  }

  public closeModal(): void {
    this.isProcess = false;
    this.dialogRef.close();
  }

  private fetchCaster() {
    this.subscription = this.currentUser$.subscribe({
      next: (res: User) => {
        if (res) {
          this.caster = res;
        } else {
          console.log('No user data received.');
        }
      },
      error: (err) => console.error('Error fetching user data:', err),
    });
  }
}
