import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { IJob, JobApplicationStatus } from '@core/interfaces';
import { JobApplication } from '@core/models';
import { MobileJobDetailComponent } from '../mobile-job-detail/mobile-job-detail.component';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { MobileUploadVideoModalComponent } from '../mobile-upload-video-modal/mobile-upload-video-modal.component';
import { MobilePostTravelCostModalComponent } from '../mobile-post-travel-cost-modal/mobile-post-travel-cost-modal.component';

@Component({
  selector: 'app-mobile-job-card',
  templateUrl: './mobile-job-card.component.html',
  styleUrls: ['./mobile-job-card.component.scss'],
})
export class MobileJobCardComponent implements OnChanges {
  @ViewChild('detailJobModal') detailJobModal: MobileJobDetailComponent;
  @ViewChild('uploadVideoModal') uploadVideoModal: MobileUploadVideoModalComponent;
  @ViewChild('postTravelCostModal') postTravelCostModal: MobilePostTravelCostModalComponent;
  @Input()
  jobs: IJob[];

  @Input()
  jobsAplicant: JobApplication[];

  @Input()
  status: string;

  @Input()
  public isFetching = false;

  constructor(protected router: ActivatedRoute, protected routers: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('jobsAplicant' in changes) {
      this.router.queryParamMap.pipe(tap()).subscribe((params) => {
        if (
          params.get('action') === 'uploadVideo' &&
          this.jobsAplicant?.find((res) => res.id === params.get('applicantID')) !== undefined &&
          this.jobsAplicant?.find((res) => res.jobId === params.get('jobID')) !== undefined
        ) {
          this.uploadVideoModal.openDialog({
            job: this.jobsAplicant?.find((res) => res.jobId === params.get('jobID')).job,
            jobApplication: this.jobsAplicant?.find((res) => res.id === params.get('applicantID')),
            userID: this.jobsAplicant?.find((res) => res.jobId === params.get('jobID')).user.id,
          });

          this.routers.navigate([], {
            queryParams: {
              action: null,
              jobID: null,
              applicantID: null,
            },
            queryParamsHandling: 'merge',
          });
        }
        if (
          params.get('action') === 'openDetailApplicant' &&
          this.jobsAplicant?.find((res) => res.id === params.get('applicantID')) !== undefined &&
          this.jobsAplicant?.find((res) => res.jobId === params.get('jobID')) !== undefined
        ) {
          this.detailJobModal.openDialog(
            this.jobsAplicant?.find((res) => res.jobId === params.get('jobID')).job,
            this.jobsAplicant?.find((res) => res.id === params.get('applicantID')),
          );

          this.routers.navigate([], {
            queryParams: {
              action: null,
              jobID: null,
              applicantID: null,
            },
            queryParamsHandling: 'merge',
          });
        }
        if (
          params.get('action') === 'talentTravelCost' &&
          this.jobsAplicant?.find((res) => res.id === params.get('applicantID')) !== undefined
        ) {
          this.postTravelCostModal.openDialog(
            this.jobsAplicant?.find((res) => res.id === params.get('applicantID')),
            params.get('noTc'),
          );

          this.routers.navigate([], {
            queryParams: {
              action: null,
              applicantID: null,
            },
            queryParamsHandling: 'merge',
          });
        }
      });
    }
  }

  public async jobDetail(selectedJob: IJob, applicant: JobApplication): Promise<void> {
    this.detailJobModal.openDialog(selectedJob, applicant);
  }

  public urgentMessageApplicant(job: JobApplication): string {
    const isJobCompleted = job.job.completed;
    const isJobCancelled = job.job.cancelled;
    const isJobApplicationConfirmed = job.status === JobApplicationStatus.confirmed;
    const isJobApplicationCancelled = job.status === JobApplicationStatus.cancelled;
    const isJobApplicationRejected = job.status === JobApplicationStatus.rejected;
    const isJobApplicationRefunded = job.status === JobApplicationStatus.refunded;
    const isJobApplicationCancelBooking = job.status === JobApplicationStatus.cancelbooking;
    const isJobApplicationRejectBooking = job.status === JobApplicationStatus.rejectbooking;

    if (
      isJobCompleted ||
      isJobCancelled ||
      isJobApplicationConfirmed ||
      isJobApplicationCancelled ||
      isJobApplicationRejected ||
      isJobApplicationRefunded ||
      isJobApplicationCancelBooking ||
      isJobApplicationRejectBooking
    ) {
      return null;
    }

    if (job.status === JobApplicationStatus.requesttobook) {
      return 'URGENT: Confirm your booking now';
    } else if (job.casterRequestedVideo && job.talentVideoId == null) {
      return 'URGENT: Caster requested your video';
    } else if (job.job.requestTalentVideo && !job.talentVideoId) {
      return 'URGENT: This job requires an audition Video';
    } else {
      return null;
    }
  }
}
