<ng-container *ngIf="jobApplication">
  <div class="wrapper">
    <app-icon icon="/assets/img/close.svg" class="close" (click)="closeModal()"></app-icon>
    <div
      class="job-request-card d-flex align-items-center flex-column"
      [ngStyle]="{
      'max-width': stateAction === 'EDIT' ? '718px' : 'auto',
      'width': stateAction === 'EDIT' ? '718px' : 'auto',
      'max-height': stateAction === 'EDIT' ? '896px' : 'auto'
      }">
      <!-- Default -->
      <ng-container *ngIf="stateAction === 'DEFAULT'">
        <div class="title" translate>Request to book</div>
        <div class="talent-info">
          <img src="{{jobApplication.user?.photo?.thumbUrl}}" alt="" />
          <p>{{user?.firstName}} {{user?.lastName}}</p>
        </div>
        <div class="desc text-center" translate *ngIf="job.negotiable; else defaultDesc">
          Send a message to the Talent to negiotate about the fee via the Chat/Message. If you have come to an
          understanding, click on 'book'.
        </div>
        <ng-template #defaultDesc>
          <div class="desc text-center" translate>You are about to send a booking request</div>
        </ng-template>
        <div class="desc text-left ml-1" *ngIf="job.bypassPayment">
          <ul>
            <li translate>The Talent can accept or reject your booking request.</li>
            <li translate>
              If you cancel a confirmed booking within 3 days before the shoot date, the Talent may charge 50% of the
              fee, 100% of the fee if canceled within 1 day before the shoot date.
            </li>
          </ul>
        </div>

        <div class="desc text-left ml-1" *ngIf="!job.bypassPayment">
          <ul>
            <li translate>The Talent can accept or reject your booking request.</li>
            <li translate>
              If you cancel a confirmed booking within 3 days before the shoot date, the Talent may charge 50% of the
              fee, 100% of the fee if canceled within 1 day before the shoot date.
            </li>
            <li translate>
              You will be directed to a payment screen where your payment will be held until after the shoot.
            </li>
            <li>
              <span translate>If you would like to receive an invoice afterwards, please email us at</span>
              <span>
                &nbsp;
                <a href="mailto:casters@casterbee.nl">casters&#64;casterbee.nl</a>
                &nbsp;
              </span>
              <span translate>or&nbsp;</span>
              <span><a href="tel:+31-6-4216-6461">+31 6 4216 6461 Jorge Bijleveld.</a></span>
            </li>
          </ul>
        </div>
        <div class="btn-wrap" *ngIf="job.negotiable; else defaultBtn">
          <button (click)="editFee()" translate>Book</button>
          <button (click)="closeModal()" style="background-color: #3f3f40 !important" translate>Cancel</button>
        </div>
        <ng-template #defaultBtn>
          <div class="btn-wrap">
            <button (click)="acceptJobApplication()" translate>Yes send now</button>
            <button (click)="editFee()" translate>Edit Fee</button>
          </div>
        </ng-template>
      </ng-container>
      <!-- Edit -->
      <ng-container *ngIf="stateAction === 'EDIT'">
        <div style="width: 400px">
          <div class="title" translate>Edit Fee</div>
          <div class="text-center">
            <div class="talent-info">
              <img src="{{jobApplication.user?.photo?.thumbUrl}}" alt="" />
              <p>{{user?.firstName}} {{user?.lastName}}</p>
            </div>
          </div>
          <form [formGroup]="form" (ngSubmit)="confirmToSubmit()">
            <div class="control-container">
              <label class="form-label" translate>Total fee:</label>
              <app-text-field
                class="form-control input-euro"
                formControlName="fee"
                type="text"
                min="0"
                max="100000"></app-text-field>
            </div>
            <div class="control-container">
              <label class="form-label">{{'Total buy-out' | translate}} :</label>
              <app-text-field
                class="form-control input-euro"
                formControlName="buyOut"
                type="text"
                min="0"
                max="100000"></app-text-field>
            </div>
            <ng-container *ngIf="job.negotiable">
              <div class="control-container">
                <label class="form-label">{{'Publishing channels' | translate}} :</label>
                <div class="w-100">
                  <app-checkbox-group class="w-100 d-block" formControlName="publishingChannels">
                    <label class="d-flex align-items-center mb-1">
                      <app-checkbox class="mr-2" value="online"></app-checkbox>
                      {{'Online' | translate}}
                    </label>
                    <label class="d-flex align-items-center mb-1">
                      <app-checkbox class="mr-2" value="print"></app-checkbox>
                      {{'Print' | translate}}
                    </label>
                    <label class="d-flex align-items-center mb-1">
                      <app-checkbox class="mr-2" value="tv-film"></app-checkbox>
                      {{'TV / Film' | translate}}
                    </label>
                  </app-checkbox-group>
                  <small translate>Assignments booked via Casterbee always have the license for all mediums.</small>
                </div>
              </div>
              <div class="control-container">
                <label class="form-label">{{'Broadcast countries' | translate}} :</label>
                <div style="width: 100%">
                  <app-select class="rounded-sm" formControlName="broadcastCountries" iconColor="white">
                    <app-option class="d-flex w-100 py-1 align-items-center text-white" translate value="worldwide">
                      {{'Worldwide' | translate}}
                    </app-option>
                    <app-option class="d-flex w-100 py-1 align-items-center text-white" translate value="other">
                      {{'Defined countries' | translate}}
                    </app-option>
                  </app-select>
                  <div
                    class="w-100 mt-2 countries"
                    *ngIf="otherBroadcastCountriesSelected()"
                    formArrayName="licenceCountries">
                    <div
                      *ngFor="let licenceCountry of licenceCountries.controls; let i = index"
                      [formGroupName]="i"
                      class="location-{{ i }}">
                      <div *ngIf="countries" class="d-flex">
                        <app-select
                          [searchable]="true"
                          class="w-100 rounded-sm mr-1 mb-2"
                          formControlName="id"
                          iconColor="white">
                          <app-option
                            *ngFor="let country of countries"
                            [value]="country.id"
                            [searchValue]="country.translateKey | translate"
                            class="d-flex w-100 py-1 align-items-center">
                            <img
                              class="flag mr-2"
                              src="https://storage.googleapis.com/casterbee-public/img/flags/{{
                                      country.isoCode.toLowerCase()
                                    }}.svg" />
                            {{ country.translateKey | translate }}
                          </app-option>
                        </app-select>
                        <button
                          type="button"
                          class="btn-icon"
                          (click)="removeLicenceCountry(i)"
                          [ngStyle]="{'display': i === 0 ? 'none' : 'block'}">
                          <app-icon fill="white" icon="/assets/img/trash.svg"></app-icon>
                        </button>
                      </div>
                    </div>
                    <div class="text-right">
                      <button type="button" (click)="addLicenceCountry()" class="btn-icon d-block" style="width: 100%">
                        {{"Add new country" | translate}}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="control-container">
                <label class="form-label">{{'Licence duration' | translate}} :</label>
                <app-text-field class="form-control" formControlName="licenceDuration" type="text"></app-text-field>
              </div>
              <div class="control-container">
                <label class="form-label">{{'Commercial use' | translate}} :</label>
                <div class="w-full">
                  <div class="checkbox-group">
                    <label>
                      <app-radio class="mr-2" [value]="false" formControlName="commercialUse"></app-radio>
                      <span translate>No</span>
                    </label>
                    <label>
                      <app-radio class="mr-2" [value]="true" formControlName="commercialUse"></app-radio>
                      <span translate>Yes</span>
                    </label>
                  </div>
                </div>
              </div>
              <hr />
            </ng-container>
            <div class="control-container">
              <label class="form-label">{{'Travel Cost' | translate}} :</label>
              <div class="w-full">
                <div class="checkbox-group">
                  <label>
                    <app-radio class="mr-2" [value]="false" formControlName="tc"></app-radio>
                    <span translate>No</span>
                  </label>
                  <label>
                    <app-radio class="mr-2" [value]="true" formControlName="tc"></app-radio>
                    <span translate>Yes</span>
                  </label>
                </div>
              </div>
            </div>
            <div *ngIf="form.get('tc')?.value" class="control-container">
              <div class="form-label"></div>
              <div class="cost-extra-input">
                <div style="display: flex; align-items: center; gap: 5px">
                  <app-text-field
                    class="form-control input-euro"
                    formControlName="tcAmount"
                    type="text"
                    min="0"
                    max="100000"></app-text-field>
                  p/km
                </div>
                <label>
                  <app-checkbox formControlName="isTcMaxAmount" class="mr-2"></app-checkbox>
                  <span translate>Max ammount</span>
                </label>
                <app-text-field
                  *ngIf="form.get('isTcMaxAmount')?.value"
                  class="form-control input-euro"
                  formControlName="tcMaxAmount"
                  type="text"
                  min="0"
                  max="100000"></app-text-field>
              </div>
            </div>
            <div class="control-container">
              <label class="form-label">{{'Parking cost' | translate}} :</label>
              <div class="checkbox-group">
                <label>
                  <app-radio class="mr-2" [value]="false" formControlName="pc"></app-radio>
                  <span translate>No</span>
                </label>
                <label>
                  <app-radio class="mr-2" [value]="true" formControlName="pc"></app-radio>
                  <span translate>Yes</span>
                </label>
              </div>
            </div>
            <div *ngIf="form.get('pc')?.value" class="control-container">
              <div class="form-label"></div>
              <div class="cost-extra-input">
                <label>
                  <app-checkbox formControlName="isPcMaxAmount" class="mr-2"></app-checkbox>
                  <span>{{'Max ammount' | translate}}</span>
                </label>
                <app-text-field
                  *ngIf="form.get('isPcMaxAmount')?.value"
                  class="form-control input-euro"
                  formControlName="pcMaxAmount"
                  type="text"
                  step="0.01"></app-text-field>
              </div>
            </div>
            <div class="btn-wrap">
              <button type="submit" [disabled]="form.invalid">{{'Send' | translate}}</button>
              <button
                type="button"
                (click)="cancel()"
                style="background-color: #3f3f40 !important; border: 0px !important">
                {{'Cancel' | translate}}
              </button>
            </div>
          </form>
        </div>
      </ng-container>
      <!-- Confirm -->
      <ng-container *ngIf="stateAction === 'CONFIRM'">
        <div class="title" translate>Are you sure?</div>
        <div class="btn-wrap">
          <button (click)="submit()" translate>Yes</button>
          <button style="background-color: #3f3f40 !important; border: 0px !important" (click)="editFee()" translate>
            Cancel
          </button>
        </div>
      </ng-container>
      <!-- Success -->
      <ng-container *ngIf="stateAction === 'SUCCESS'">
        <div class="success-img mt-2">
          <img src="assets/img/mobile/check-white.svg" alt="Success" />
        </div>
        <div class="title" translate>Booking request has been sent</div>
        <div class="message">
          <p translate>
            Great! A booking request has been send. Please don't forget to reject the other Talents. This higher the
            chances that they will apply to your job the next time.
          </p>
        </div>
        <img src="/assets/img/reject-talents-meme-2.png" alt="" style="width: 250px; margin-top: 20px" />
        <div class="spacer"></div>
        <div class="btn-wrap">
          <button style="background-color: #3f3f40 !important; border: 0px !important" (click)="closeModal()" translate>
            Close
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
