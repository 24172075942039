<div class="d-flex flex-column w-100 text-white">
  <div class="wrapper">
    <app-icon icon="/assets/img/close.svg" class="close" (click)="closeModal()"></app-icon>
    <div class="job-request-card">
      <div class="title" translate>Offer job</div>
      <div style="width: 700px">
        <div class="message">
          <img src="/assets/img/mobile/lamp.svg" alt="" />
          <p>
            {{'Only choose this if you already discussed the details with the Talent directly.' | translate}}
            <br />
            {{"If the shoot hasn't takent place yet, add the date/period in the shoot description" | translate}}
          </p>
        </div>
        <form [formGroup]="form" (ngSubmit)="submit()">
          <div class="control-container">
            <label class="form-label m-auto">{{'Title' | translate}}:</label>
            <app-text-field class="form-control" formControlName="title" type="text"></app-text-field>
          </div>
          <div class="control-container">
            <label class="form-label" translate>{{'Description' | translate}}:</label>
            <textarea
              formControlName="shortDescription"
              class="form-control"
              style="height: 73px !important"
              placeholder="{{'Example: Urban Lifestyle Campaign with a €500 fee for a half-day shoot, €300 buyout, 1-year license for use on social media, website, and digital ads; shooting on March 15, 2024, 10:00 AM - 2:00 PM in downtown Amsterdam' | translate}}"></textarea>
          </div>
          <div style="width: 60%">
            <div class="control-container">
              <label class="form-label m-auto" translate>{{'Total fee' | translate}}:</label>
              <app-text-field
                class="form-control input-euro"
                formControlName="rate"
                type="text"
                min="0"
                max="100000"></app-text-field>
            </div>
            <!-- <div class="control-container">
              <label class="form-label  m-auto">{{'Buyout' | translate}} :</label>
              <app-text-field class="form-control input-euro" formControlName="buyOut" type="text" min="0"
                max="100000"></app-text-field>
            </div> -->
            <div class="control-container">
              <label class="form-label m-auto">{{'Travel cost' | translate}} :</label>
              <div class="w-full">
                <div class="checkbox-group">
                  <label>
                    <app-radio class="mr-2 haveTravelCost" [value]="false" formControlName="tc"></app-radio>
                    <span translate>No</span>
                  </label>
                  <label>
                    <app-radio class="mr-2 haveTravelCost" [value]="true" formControlName="tc"></app-radio>
                    <span translate>Yes</span>
                  </label>
                </div>
              </div>
            </div>
            <div *ngIf="form.get('tc')?.value" class="control-container">
              <div class="form-label m-auto"></div>
              <div class="cost-extra-input">
                <div style="display: flex; align-items: center; gap: 5px">
                  <app-text-field
                    class="form-control input-euro"
                    formControlName="tcAmount"
                    type="text"
                    min="0"
                    max="100000"></app-text-field>
                  p/km
                </div>
                <label>
                  <app-checkbox formControlName="isTcMaxAmount" class="mr-2"></app-checkbox>
                  <span translate>{{'Max ammount' | translate}}</span>
                </label>
                <app-text-field
                  *ngIf="form.get('isTcMaxAmount')?.value"
                  class="form-control input-euro"
                  formControlName="tcMaxAmount"
                  type="text"
                  min="0"
                  max="100000"></app-text-field>
              </div>
            </div>
            <div class="control-container">
              <label class="form-label">{{'Parking cost' | translate}} :</label>
              <div class="checkbox-group">
                <label>
                  <app-radio class="mr-2" [value]="false" formControlName="pc"></app-radio>
                  <span translate>No</span>
                </label>
                <label>
                  <app-radio class="mr-2" [value]="true" formControlName="pc"></app-radio>
                  <span translate>Yes</span>
                </label>
              </div>
            </div>
            <div *ngIf="form.get('pc')?.value" class="control-container">
              <div class="form-label"></div>
              <div class="cost-extra-input">
                <label>
                  <app-checkbox formControlName="isPcMaxAmount" class="mr-2"></app-checkbox>
                  <span>{{'Max ammount' | translate}}</span>
                </label>
                <app-text-field
                  *ngIf="form.get('isPcMaxAmount')?.value"
                  class="form-control input-euro"
                  formControlName="pcMaxAmount"
                  type="text"
                  step="0.01"></app-text-field>
              </div>
            </div>
          </div>
          <div class="text-center">
            <div class="btn-wrap">
              <button
                type="button"
                class="mr-3"
                (click)="closeModal()"
                style="background-color: #3f3f40 !important; border: 0px !important">
                {{'Back' | translate}}
              </button>
              <button
                type="submit"
                class="ml-3 d-flex align-items justify-content-center"
                [disabled]="form.invalid || isProcess">
                <app-spinner *ngIf="isProcess" class="spinner align-self-center justify-self-center"></app-spinner>
                <ng-container *ngIf="!isProcess">{{'Send' | translate}}</ng-container>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
