import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { LocalFile } from '@core/models';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  public constructor(private http: HttpClient) {}

  public readFile(file: File): Promise<string> {
    if (!file) {
      return;
    }

    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result.toString());
      reader.readAsDataURL(file);
    });
  }

  public upload(file: any): Observable<LocalFile> {
    return this.http.post<LocalFile>(`${environment.apiBaseUrl}/media/${file.id}`, file);
  }

  public uploadAndResize(file: any, size: number): Observable<LocalFile> {
    return this.http.post<LocalFile>(`${environment.apiBaseUrl}/media/${file.id}/resize/${size}`, file);
  }

  // public uploadFileV2(file: any): Observable<{ status: string; message: any }> {
  //   const uploadReq = new HttpRequest('POST', `${environment.apiBaseUrl}/media/${file.id}`, file, {
  //     headers: new HttpHeaders(),
  //     reportProgress: true,
  //   });

  //   return this.http.request(uploadReq).pipe(
  //     map((event: any) => {
  //       switch (event.type) {
  //         // Report upload progress
  //         case HttpEventType.UploadProgress:
  //           if (event.total) {
  //             const percentDone = Math.round((100 * event.loaded) / event.total);
  //             return { status: 'progress', message: percentDone }; // Show upload progress
  //           }
  //           break;

  //         // Handle upload completion and process response
  //         case HttpEventType.Response:
  //           // Now that upload is complete, show 100% progress
  //           return { status: 'success', message: event.body as LocalFile }; // Handle completed upload
  //       }
  //     }),
  //     catchError((error) => {
  //       return [{ status: 'error', message: error.message }];
  //     }),
  //   );
  // }

  public uploadFileV2(
    file: any,
    type: string,
    payload: string,
    id: string,
  ): Observable<{ status: string; message: any }> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('type', type);
    formData.append('payload', payload);

    const uploadReq = new HttpRequest('POST', `${environment.apiBaseUrl}/media/upload/video/${id}`, formData, {
      headers: new HttpHeaders(),
      reportProgress: true,
    });

    return this.http.request(uploadReq).pipe(
      map((event: any) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            if (event.total) {
              const percentDone = Math.round((100 * event.loaded) / event.total);
              return { status: 'progress', message: percentDone };
            }
            break;

          case HttpEventType.Response:
            return { status: 'success', message: event.body as LocalFile };
        }
      }),
      catchError((error) => {
        return [{ status: 'error', message: error.message }];
      }),
    );
  }
}
